<template>
  <div class="columns is-multiline">
    <div class="column is-3"></div>
    <div class="column is-6">
      <h3 class="has-text-weight-bold is-size-2">Admin Login</h3>
      <div class="is-size-5 asphalt-grey" style="padding-bottom: 16px">
        Log In to access Admin Payment
      </div>
      <div class="columns is-multiline">
        <div class="column is-12">
          <validation-provider
            name="email"
            rules="required|email"
            v-slot="{ errors }"
          >
            <b-field
              label="Email"
              :type="errors[0] && 'is-danger'"
              :message="errors[0]"
            >
              <b-input
                v-model="email"
                expanded
                placeholder="e.g admin@roketin.com"
              ></b-input>
            </b-field>
          </validation-provider>
        </div>
        <div class="column is-12 login-page__password-container">
          <validation-provider
            name="password"
            rules="required"
            v-slot="{ errors }"
          >
            <b-field
              label="Password"
              :type="errors[0] && 'is-danger'"
              :message="errors[0]"
            >
              <b-input
                v-model="password"
                expanded
                password-reveal
                type="password"
                placeholder="Password"
              ></b-input>
            </b-field>
          </validation-provider>
        </div>
        <!-- commented because this feature is going to be created later -->
        <!-- <div class="column is-6">
          <b-field>
            <b-checkbox v-model="isRemember">
              <span class="asphalt-grey is-size-5">Keep me signed in</span>
            </b-checkbox>
          </b-field>
        </div> -->
        <div class="column is-12 login-page__forgot-password-container">
          <router-link
            to="forgot-password"
            class="is-size-5 has-text-weight-semi-bold forgot-password"
          >
            Forgot Password?
          </router-link>
        </div>
        <div class="column is-12">
          <b-button
            @click.native="login"
            :loading="isLoading"
            :disabled="isLoading"
            expanded
          >
            Login
          </b-button>
        </div>
      </div>
    </div>
    <div class="column is-3"></div>
  </div>
</template>

<script>
import { showToast } from '@/services/util'
export default {
  data() {
    return {
      email: null,
      password: null,
      isLoading: false,
    }
  },

  methods: {
    async login() {
      this.isLoading = true
      let email = this.email
      let password = this.password
      let redirect = '/'

      await this.$store
        .dispatch('auth/login', { email, password })
        .then(() => {
          this.$router.push(redirect)
        })
        .catch((error) => {
          console.log(error)
          showToast(
            'Login failed. Your email or password is incorrect.',
            'is-danger',
            'is-top'
          )
        })
      this.isLoading = false
    },
  },
}
</script>
